import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const PracticalInformations = () => (
  <Container className="rubinPracticalInformations" id="o-divadle">
    <Row style={{ marginBottom: '2rem' }}>
      <Col lg="3" md="24" className="inner-navigation">
        <p><strong>praktické informace:</strong></p>
        <p><a href="#lide">lidé</a></p>
      </Col>
      <Col lg="9" md="24">
        <iframe
          title="Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2559.937155130627!2d14.400709215511178!3d50.08746347942695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b94fb3c857749%3A0xd96483a7e50bbba6!2sA+Studio+Rub%C3%ADn!5e0!3m2!1sen!2scz!4v1538925104731"
          width="397"
          height="297"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
          ></iframe>
      </Col>
      <Col lg="12" md="24">
        <h3>A studio Rubín</h3>
        <p>Malostranské nám. 9, 118 00 Praha 1</p>
        <p>IČ: 250 89 501</p>
        <p>
          tel: <a href="tel:+420257221158">+420 257 221 158</a>
          <br />
          e-mail: <a href="mailto:astudiorubin@seznam.cz">astudiorubin@seznam.cz</a>
        </p>
      </Col>
    </Row>
    <Row style={{ marginBottom: '2rem' }}>
      <Col lg={{ size: 9, offset: 3 }} md="24">
        <h3>otevírací doba pokladny</h3>
        <p>po&mdash;pá: 16:30&mdash;19:30</p>
        <p>so&mdash;ne a svátky pouze ve dny představení: 17:00&mdash;19:30</p>
        <p>pokladna příjímá tyto způsoby platby</p>
        <ul>
          <li>hotově, bohužel není možná platba kartou</li>
          <li>dárkové poukazy A studia Rubín</li>
          <li>hromadné objednávky nad 8 kusů vstupenek s 20% slevou</li>
          <li>
            objednávky na fakturu:
            <a href="mailto:astudiorubin@seznam.cz">astudiorubin@seznam.cz</a>
          </li>
        </ul>
      </Col>
      <Col lg="6" md="12">
        <h3>otevírací doba kavárny</h3>
        <p>po&mdash;pá: 13:00&mdash;19:30</p>
        <p>so&mdash;ne a svátky pouze ve dny představení: 16:00&mdash;19:30</p>
      </Col>
      <Col lg="6" md="12">
        <h3>otevírací doba baru</h3>
        <p>pouze v době konání představení a koncertů: 18:00 do noci</p>
        <p>během divadelního představení je bar zavřen</p>
      </Col>
    </Row>
    <Row style={{ marginBottom: '2rem' }}>
      <Col lg={{ size: 12, offset: 12 }} md="24">
        <h3>ceny vstupenek:</h3>
        <dl className="row">
          <dt className="col-lg-8">250 Kč</dt>
          <dd className="col-lg-16">plné vstupné</dd>
          <dt className="col-lg-8">150 Kč</dt>
          <dd className="col-lg-16">
            snížené vstupné &mdash; platí pro studenty, seniory a ZTP
          </dd>
          <dt className="col-lg-8">70 Kč</dt>
          <dd className="col-lg-16">
            zvýhodněné vstupenky pro studenty AMU, JAMU, uměnovědných
            oborů a konzervatoří je možné zakoupit v kavárně A studio
            Rubín při předložení indexu či ISIC/ITIC card půl hodiny
            před konáním představení, pokud není vyprodáno.
          </dd>
          <dt className="col-lg-8">novinářské vstupenky</dt>
          <dd className="col-lg-16">
            pouze po předchozí rezervaci na
            <a href="mailto:p.umlaufova@gmail.com">p.umlaufova@gmail.com</a>
          </dd>
        </dl>
        <p style={{ fontWeight: 'bold' }}>Změna programu vyhrazena</p>
        <p style={{ fontWeight: 'bold' }}>
          Vstupenky vyměňujeme nebo vrazíme vstupné pouze při zrušeném představení bez náhrady.
        </p>
      </Col>
    </Row>
    <Row style={{ marginBottom: '2rem' }}>
      <Col lg="3" md="24" className="inner-navigation">
        <div id="lide" />
        <p><a href="#o-divadle">praktické informace</a></p>
        <p><strong>lidé:</strong></p>
      </Col>
      <Col lg={{ size: 6, offset: 3 }} md="8">
        <h3>ředitel divadla:</h3>
        <p><strong>Ondřej Glazar</strong></p>
        <p>e-mail: <a href="mailto:glazar@astudorubin.cz">glazar@astudiorubin.cz</a></p>
      </Col>
      <Col lg="6" md="8">
        <h3>umělecká šéfka:</h3>
        <p><strong>Dagmar Radová</strong></p>
        <p>e-mail: <a href="mailto:dagmar.radova@gmail.com">dagmar.radova@gmail.com</a></p>
      </Col>
      <Col lg="6" md="8">
        <h3>produkce:</h3>
        <p><strong>Barbora Kunstovná</strong></p>
        <p>
          tel.: <a href="tel:420-603-563-020">+420 603 563 020</a><br/>
          e-mail: <a href="mailto:b.kunstovna@gmail.com">b.kunstovna@gmail.com</a>
        </p>
      </Col>
    </Row>
    <Row style={{ marginBottom: '2rem' }}>
      <Col lg={{ size: 6, offset: 6 }} md="8">
        <h3>PR a brandmanager:</h3>
        <p><strong>Pavla Umlaufová</strong></p>
        <p>
          tel.: <a href="tel:420-723-901-326">+420 723 901 326</a><br/>
          e-mail: <a href="mailto:p.umlaufova@gmail.com">p.umlaufova@gmail.com</a>
        </p>
      </Col>
      <Col lg="6" md="8">
        <h3>grafické designerky:</h3>
        <p><strong>Alexandra Šliková</strong></p>
        <p>e-mail: <a href="mailto:slikova4@seznam.cz">slikova4@seznam.cz</a></p>
      </Col>
      <Col lg="6" md="8">
        <h3>&nbsp;</h3>
        <p><strong>Karolína Matušková</strong></p>
        <p>e-mail: <a href="mailto:karolina.mat@seznam.cz">karolina.mat@seznam.cz</a></p>
      </Col>
    </Row>
    <Row style={{ marginBottom: '2rem' }}>
      <Col lg={{ size: 6, offset: 6 }} md="8">
        <h3>hudební dramaturg:</h3>
        <p><strong>Ondřej Mataj</strong></p>
        <p>tel.: <a href="tel:420-739-961-042">+420 739 961 042</a></p>
      </Col>
      <Col lg="6" md="8">
        <h3>technika divadla:</h3>
        <p><strong>David Oupor</strong></p>
        <p>
          tel.: <a href="tel:420-606-612-178">+420 606 612 178</a><br/>
          e-mail: <a href="mailto:technika@astudiorubin.cz">technika@astudiorubin.cz</a>
        </p>
      </Col>
      <Col lg="6" md="8">
        <h3>zájezdy:</h3>
        <p><strong>Gábina Kolečková</strong></p>
        <p>
          tel.: <a href="tel:420-736-674-444">+420 736 674 444</a><br/>
          e-mail: <a href="mailto:gabriela.koleckova@gmail.com">gabriela.koleckova@gmail.com</a>
        </p>
      </Col>
    </Row>
    <Row style={{ marginBottom: '2rem' }}>
      <Col lg={{ size: 6, offset: 6 }} md="8">
        <h3>provoz baru a kavárny:</h3>
        <p><strong>Johana Kolomazníková</strong></p>
        <p>
          tel.: <a href="tel:420-776-322-762">+420 776 322 762</a><br/>
          e-mail: <a href="mailto:johanakolomaznikova@gmail.com">johanakolomaznikova@gmail.com</a>
        </p>
      </Col>
    </Row>
  </Container>
);

export default PracticalInformations;
