import {
  EAvailability,
  ECategory,
  EKind,
  IRepertoarItem,
  IPrice,
} from './types.d';

const DEFAULT_PRICE: IPrice = {
  value: 250,
  currency: 'Kč',
};

export const THELMA_A_SELMA: IRepertoarItem = {
  title: 'Thelma a Selma',
  description: 'Dagmar Radová',
  image: '/assets/repertoar/thelma_a_selma.jpg',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};

export const TRAKTAT_O_STEPNIM_VLKU: IRepertoarItem = {
  title: 'Traktát o stepním vlku',
  description: 'Jan Kačena a kol.',
  image: '/assets/repertoar/traktat_o_stepnim_vlku.jpg',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};

export const NEVIM: IRepertoarItem = {
  title: 'Nevim',
  description: 'Vojtěch Štěpánek',
  image: '/assets/repertoar/nevim.jpg',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};

export const JA_ME_DRUHE_JA_A_JA_A: IRepertoarItem = {
  title: 'Já, mé druhé já a já a',
  description: 'podle blogu Jiřího Vaňka Adam Svozil a Kristýna Kosová',
  image: '/assets/repertoar/ja_me_druhe_ja_a_ja_a.jpg',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};

export const $LAST: IRepertoarItem = {
  title: '$last',
  image: '/assets/repertoar/slast.jpg',
  description: 'Kryštof Pavelka',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};

export const KRALOVNA_DUCHU: IRepertoarItem = {
  title: 'Královna duchů',
  description: 'Elfriede Jelinek',
  image: '/assets/repertoar/kralovna_duchu.jpg',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};

export const ZVRHLA_MARGARET: IRepertoarItem = {
  title: 'Zvrhlá Margaret',
  description: 'Tomáš Dianiška',
  image: '/assets/repertoar/zvrhla_margaret.jpg',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};

export const LONELY_HORNY_ONLY: IRepertoarItem = {
  title: 'Lonely horny only',
  description: 'Ondřej Štefaňák, Dagmar Radová a kol.',
  image: '/assets/repertoar/lonely_horny_only.jpg',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};

export const MIRIAM: IRepertoarItem = {
  title: 'stojím ve tmě abych viděl světlo dělám zlo aby dobro kvetlo MIRIAM',
  description: 'Jakub Deml, Jan Kačena a kolektiv',
  image: '/assets/repertoar/miriam.jpg',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};

export const PADESATKA: IRepertoarItem = {
  title: 'Padesátka',
  description: 'Petr Kolečko',
  image: '/assets/repertoar/padesatka.jpg',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};

export const ANTIGONE: IRepertoarItem = {
  title: 'Anti-gone: Blackout',
  description: 'Lucie Ferenzová a kolektiv autorů pod vedením Sofokla',
  image: '/assets/repertoar/antigone.jpg',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};

export const HUBTE_TRAMPY_SEROU_V_LESE: IRepertoarItem = {
  title: 'Hubte trampy, serou v lese, aneb z cancáku René Ilči Baumana',
  description: 'Ondřej Novotný',
  image: '/assets/repertoar/hubte_trampy_serou_v_lese.jpg',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};

export const POTWORY: IRepertoarItem = {
  title: 'Potwory',
  description: 'Ewa Zembok podle románu Sylwie Chutnik',
  image: '/assets/repertoar/potwory.jpg',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};

export const OBLUDOV: IRepertoarItem = {
  title: 'Obludov',
  description: 'Jaroslav Žváček',
  image: '/assets/repertoar/obludov.jpg',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};

export const PRIROZENI: IRepertoarItem = {
  title: 'Přirození',
  description: 'Petr Kolečko',
  image: '/assets/repertoar/prirozeni.jpg',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};

export const MOJE_MALA_UCHYLKA: IRepertoarItem = {
  title: 'Moje malá úchylka',
  description: 'Tomáš Dianiška',
  image: '/assets/repertoar/moje-mala-uchylka.jpg',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};

export const BURNOUT: IRepertoarItem = {
  title: 'BurnOut aneb Vyhoř!',
  description: 'Jan Frič / Dagmar Radová a kol.',
  image: '/assets/repertoar/burnout.png',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};


export const KLAUNOVY_NAZORY: IRepertoarItem = {
  title: 'Klaunovy názory',
  description: 'Lucie Ferenzová',
  image: '/assets/repertoar/klaunovy_nazory.png',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};

export const TUMOR: IRepertoarItem = {
  title: 'Tumor: karcinogenní romance',
  description: 'Dagmar Radová & T.I.T.S.',
  image: '/assets/repertoar/tumor.jpg',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};

export const VECI: IRepertoarItem = {
  title: 'Věci',
  description: 'Eva Rysová a Vojtěch Bárta',
  image: '/assets/repertoar/veci.jpg',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};

export const VZTEK: IRepertoarItem = {
  title: 'Vztek',
  description: 'Lucie Ferenzová',
  image: '/assets/repertoar/vztek.jpg',
  price: DEFAULT_PRICE,
  category: ECategory.Theatre,
  kind: EKind.Regular,
  availability: EAvailability.Available,
  link: '',
  date: '',
};
