import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const partners = [
  {
    href: 'https://www.mkcr.cz/',
    name: 'Ministerstvo kultury',
    image: require('../../assets/partners/ministerstvo-kultury.jpg'),
  },
  {
    href: 'http://www.scena.cz/',
    name: 'scena.cz',
    image: require('../../assets/partners/scena-cz.jpg'),
  },
  {
    href: 'http://www.expresfm.cz/',
    name: 'EXPRESFM',
    image: require('../../assets/partners/expresfm.jpg'),
  },
  {
    href: 'https://www.praha1.cz/',
    name: 'Praha 1',
    image: require('../../assets/partners/praha-1.jpg'),
  },
  {
    href: 'http://www.praha.eu',
    name: 'praha.eu',
    image: require('../../assets/partners/praha.jpg'),
  },
  {
    href: 'http://plantdesign.cz/',
    name: 'Plant Design',
    image: require('../../assets/partners/plant-design.jpg'),
  },
  {
    href: 'https://www.blueberry.io/',
    name: 'blueberry.io',
    image: require('../../assets/partners/blueberry.png'),
  },
];

const Partners = () => (
  <Container className="rubinPartners">
    <Row>
      <Col>
        <div className="d-flex flex-row justify-content-between align-items-start">
          {partners.map(partner => (
            <div key={partner.name} className="p-2">
              <a
                href={partner.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={partner.image}
                  alt={partner.name}
                  height="50"
                />
              </a>
            </div>
          ))}
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <p className="text-center">
          <small>Hlavní mediální partner: ExpresFM. Za podpory HMP částkou 1 600 000 Kč.</small>
        </p>
      </Col>
    </Row>
  </Container>
);

export default Partners;
