import React, { Component } from 'react';
import {
  Container,
  Row,
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  NavbarBrand,
} from 'reactstrap';

import aStudioRubin from '../../assets/layout/a-studio-rubin.svg';

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <Navbar expand="md" light>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar style={{ paddingLeft: '15px' }}>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="#program" className="rubin-link-to-program" style={{ color: '#000', fontSize: '1.2em', marginRight: '2em' }}>program</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#o-divadle" className="rubin-link-to-theatre" style={{ color: '#000', fontSize: '1.2em', marginRight: '2em' }}>o divadle</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
        <NavbarBrand href="/">
          <img src={aStudioRubin} alt="A studio Rubín" height="24" />
        </NavbarBrand>
      </Navbar>
    );
  }
}

export default NavBar;
