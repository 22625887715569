import * as r from './presets';
import { IRepertoar, EKind, EAvailability, ECategory } from './types.d';

const repertoar: IRepertoar[] = [
  {
    month: 'listopad',
    data: [
      {
        ...r.BURNOUT,
        date: '2019-11-03 19:30',
        link: 'https://goout.net/cs/listky/burn-out-aneb-vyhor/ilif/',
      },
      {
        ...r.VZTEK,
        date: '2019-11-04 19:30',
        link: 'https://goout.net/cs/listky/vztek/llif/',
      },
      {
        ...r.LONELY_HORNY_ONLY,
        date: '2019-11-05 19:30',
        link: 'https://goout.net/cs/listky/lonely-horny-only/mlif/',
      },
      {
        ...r.OBLUDOV,
        date: '2019-11-08 19:30',
        link: 'https://goout.net/cs/listky/obludov/nlif/',
        fbEvent: 'https://www.facebook.com/events/370974157120926/?event_time_id=370974163787592',
      },
      {
        ...r.ZVRHLA_MARGARET,
        date: '2019-11-10 19:30',
        link: 'https://goout.net/cs/listky/zvrhla-margaret/fpkf/',
        fbEvent: 'https://www.facebook.com/events/1324976261003129/',
      },
      {
        ...r.THELMA_A_SELMA,
        date: '2019-11-11 19:30',
        link: 'https://goout.net/cs/divadlo/thelma-a-selma/mliuc/+ntveo/',
        fbEvent: 'https://www.facebook.com/events/931494900557437/',
      },
      {
        ...r.PADESATKA,
        date: '2019-11-12 19:30',
        link: 'https://goout.net/cs/listky/padesatka/uqif/',
        fbEvent: 'https://www.facebook.com/events/1136028423452822/',
      },
      {
        ...r.VECI,
        date: '2019-11-13 19:30',
        link: 'https://goout.net/cs/listky/veci/vqif/',
        fbEvent: 'https://www.facebook.com/events/937442956588388/?event_time_id=1009934539339229',
      },
      {
        ...r.VECI,
        date: '2019-11-14 19:30',
        link: 'https://goout.net/cs/listky/veci/wqif/',
        fbEvent: 'https://www.facebook.com/events/937442956588388/?event_time_id=1009934542672562',
      },
      {
        ...r.OBLUDOV,
        date: '2019-11-16 19:30',
        link: 'https://goout.net/cs/listky/obludov/xqif/',
        fbEvent: 'https://www.facebook.com/events/370974157120926/?event_time_id=370974160454259',
      },
      {
        ...r.HUBTE_TRAMPY_SEROU_V_LESE,
        description: `${r.HUBTE_TRAMPY_SEROU_V_LESE.description} (alternace Lenka Zahradnická)`,
        date: '2019-11-17 18:00',
        link: 'https://goout.net/cs/listky/hubte-trampy-serou-v-lese/yqif/',
        fbEvent: 'https://www.facebook.com/events/389513471717225/',
      },
      {
        title: 'Návraty do Rubínu',
        description: 'Sylva Fischerová a Michael Vandebril',
        image: '/assets/repertoar/web_navraty_poezie.jpg',
        category: ECategory.Theatre,
        kind: EKind.Regular,
        availability: EAvailability.Available,
        date: '2019-11-18 19:30',
        link: '',
        fbEvent: 'https://www.facebook.com/events/555570021864523/',
      },
      {
        ...r.NEVIM,
        date: '2019-11-21 19:30',
        link: 'https://goout.net/cs/listky/nevim/arif/',
        fbEvent: 'https://www.facebook.com/events/1327280700763658/',
      },
      {
        title: '64. Šrámkova Sobotka, podzimní edice',
        description: '',
        image: '/assets/repertoar/web_sramkova_sobotka.jpg',
        category: ECategory.Theatre,
        kind: EKind.Regular,
        availability: EAvailability.Available,
        date: '2019-11-23 19:30',
        link: '',
        fbEvent: 'https://www.facebook.com/events/410988329615527/',
      },
      {
        title: 'Závěrečný večer festivalu Den poezie',
        description: '',
        image: '/assets/repertoar/web_zaver_poezie.jpg',
        category: ECategory.Theatre,
        kind: EKind.Regular,
        availability: EAvailability.Available,
        date: '2019-11-24 19:30',
        link: '',
        fbEvent: 'https://www.facebook.com/events/2481853028549904/',
      },
      {
        ...r.KLAUNOVY_NAZORY,
        date: '2019-11-25 19:30',
        link: 'https://goout.net/cs/listky/klaunovy-nazory/crif/',
        fbEvent: 'https://www.facebook.com/events/967402856927842/',
      },
      {
        ...r.JA_ME_DRUHE_JA_A_JA_A,
        date: '2019-11-28 19:30',
        kind: EKind.Derniere,
        link: 'https://goout.net/cs/listky/ja-me-druhe-ja-a-ja-a/drif/',
        fbEvent: 'https://www.facebook.com/events/416151082377299/',
      },
      {
        ...r.TRAKTAT_O_STEPNIM_VLKU,
        title: `${r.TRAKTAT_O_STEPNIM_VLKU.title} (autodráha)`,
        date: '2019-11-29 19:30',
        link: 'https://goout.net/cs/listky/traktat-o-stepnim-vlku/erif/',
        fbEvent: 'https://www.facebook.com/events/474500333146427/',
      },
      {
        title: 'Jezero',
        description: 'Lucie Ferenzová',
        date: '2019-11-30 19:30',
        category: ECategory.Theatre,
        kind: EKind.Regular,
        availability: EAvailability.Available,
        image: '/assets/repertoar/jezero.jpg',
        price: {
          currency: 'Kč',
          value: 250,
        },
        link: 'https://goout.net/cs/divadlo/jezero/appwe/+uiyho/',
        fbEvent: 'https://www.facebook.com/events/745978169183302/',
      }
    ]
  },
  {
    month: 'prosinec',
    data: [
      {
        ...r.PADESATKA,
        date: '2019-12-01 19:30',
        link: 'https://goout.net/cs/listky/padesatka/fjof/',
        fbEvent: 'https://www.facebook.com/events/574082370067123/',
      },
      {
        ...r.KRALOVNA_DUCHU,
        date: '2019-12-03 19:30',
        kind: EKind.Derniere,
        link: 'https://goout.net/cs/listky/kralovna-duchu/hjof/',
        fbEvent: 'https://www.facebook.com/events/2078419292258306/',
      },
      {
        ...r.LONELY_HORNY_ONLY,
        date: '2019-12-05 19:30',
        link: 'https://goout.net/cs/listky/lonely-horny-only/ljof/',
        fbEvent: 'https://www.facebook.com/events/1010311812643977/',
      },
//      {
//        ...r.JEZERO,
//        date: '2019-12-06 19:30',
//        link: 'https://goout.net/cs/listky/jezero/mjof/',
//        fbEvent: 'https://www.facebook.com/events/1439723262857698/',
//      },
      {
        ...r.OBLUDOV,
        date: '2019-12-07 19:30',
        link: 'https://goout.net/cs/listky/obludov/njof/',
        fbEvent: 'https://www.facebook.com/events/370974157120926/?event_time_id=392651698286505',
      },
      {
        ...r.KLAUNOVY_NAZORY,
        date: '2019-12-11 19:30',
        link: 'https://goout.net/cs/listky/klaunovy-nazory/ojof/',
        fbEvent: 'https://www.facebook.com/events/834369653681313/',
      },
      {
        ...r.VECI,
        date: '2019-12-13 19:30',
        link: 'https://goout.net/cs/listky/veci/pjof/',
        fbEvent: 'https://www.facebook.com/events/937442956588388/?event_time_id=1035961440069872',
      },
      {
        ...r.ZVRHLA_MARGARET,
        date: '2019-12-15 19:30',
        link: 'https://goout.net/cs/listky/zvrhla-margaret/qjof/',
        fbEvent: 'https://www.facebook.com/events/2548435468572619/',
      },
      {
        ...r.NEVIM,
        date: '2019-12-17 19:30',
        link: 'https://goout.net/cs/listky/nevim/rjof/',
        fbEvent: 'https://www.facebook.com/events/514135832769892/',
      },
//      {
//        ...r.JOZKA,
//        date: '2019-12-18 19:30',
//        link: 'https://goout.net/cs/listky/jozkalipnikjeboziclovekaneumilhat-vanocni-special/ukof/',
//        fbEvent: '',
//      },
      {
        ...r.ANTIGONE,
        date: '2019-12-19 19:30',
        link: 'https://goout.net/cs/listky/anti-gone-blackout/sjof/',
        fbEvent: 'https://www.facebook.com/events/430068147558293/',
      },
      {
        ...r.BURNOUT,
        date: '2019-12-20 19:30',
        link: 'https://goout.net/cs/listky/burn-out-aneb-vyhor/tjof/',
        fbEvent: 'https://www.facebook.com/events/492799934653929/',
      },
      {
        ...r.HUBTE_TRAMPY_SEROU_V_LESE,
        date: '2019-12-21 19:30',
        link: 'https://goout.net/cs/listky/hubte-trampy-serou-v-lese/ujof/',
        fbEvent: 'https://www.facebook.com/events/628039957600779/',
      },
//      {
//        ...r.KONCER,
//        date: '2019-12-22 22:00',
//        link: 'https://goout.net/cs/listky/xavier-baumaxa/vjof/',
//        fbEvent: '',
//      },
    ]
  }
];

export default repertoar;
