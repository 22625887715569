import React, { Component } from 'react';
import {
  Container,
} from 'reactstrap';

import repertoar from './data/repertoar';
import RepertoarList from './components/repertoar/RepertoarList';
import NavBar from './components/navBar/NavBar';
import Footer from './components/footer/Footer';
import Partners from './components/partners/Partners';
import PracticalInformations from './components/practicalInformations/PracticalInformations';
import imgJumbotron from './assets/jumbotron/rezervace-vstupenek.jpg';
import buttonGoOut from './assets/layout/button-goout.svg';

import './styles/_theme.scss';

const repertoarNavigation = {
  'listopad': (
    <div id="listopad">
      <p><strong>listopad</strong></p>
      <p><a href="#prosinec">prosinec</a></p>
    </div>
  ),
  'prosinec': (
    <div id="prosinec">
      <p><a href="#listopad">listopad</a></p>
      <p><strong>prosinec</strong></p>
    </div>
  ),
};

class App extends Component {
  render() {
    return (
      <div>
        <NavBar />
        <Container fluid className="rubin-jumbotron" style={{ position: 'relative' }}>
          <img src={imgJumbotron} alt="Rezervace vstupenek" className="img-fluid" />
          <a
            href="http://www.goout.cz"
            style={{ display: 'inline-block', position: 'absolute', right: '3rem', bottom: '0.7rem', width: '17rem' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={buttonGoOut} alt="Rezervace vstupenek" width="100%" />
          </a>
        </Container>
        <Container id="program" style={{ paddingBottom: '1em' }}>
          {repertoar.map(({ month, data }) => (
            <RepertoarList
              key={month}
              data={data}
              navigation={repertoarNavigation[month]}
            />
          ))}
        </Container>
        <PracticalInformations />
        <Footer />
        <Partners />
      </div>
    );
  }
}

export default App;
