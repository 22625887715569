import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => (
  <Container className="rubinFooter" fluid>
    <Container>
      <Row>
        <Col lg="6" sm="12" xs="24">
          <h4>Sledujte nás!</h4>
          <ul>
            <li><a
              href="https://www.facebook.com/astudiorubin"
              target="_blank"
              rel="noopener noreferrer"
            >facebook</a></li>
            <li><a
              href="https://www.instagram.com/a_studio_rubin"
              target="_blank"
              rel="noopener noreferrer"
            >instagram</a></li>
          </ul>
        </Col>
        <Col lg="6" sm="12" xs="24">
          <h4>O divadle</h4>
          <ul>
            <li><a href="#o-divadle">praktické informace</a></li>
          </ul>
        </Col>
        <Col lg="6" sm="12" xs="24">
          <h4>Vstupenky</h4>
          <ul>
            <li><a
              href="https://goout.net/cs/divadla/a-studio-rubin/gd/"
              target="_blank"
              rel="noopener noreferrer"
            >rezervace</a></li>
          </ul>
        </Col>
        <Col lg="3" sm="6" xs="12">
          <h4>Pro média</h4>
          <ul>
            <li>
              <a
                href="/zpravy/vyrocni_zprava_2017_web.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                výroční zprávy
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  </Container>
);

export default Footer;
