export enum ECategory {
  Theatre = 'theatre',
  Concert = 'concert',
};

export enum EKind {
  Premiere = 'premiere',
  Regular = 'regular',
  Guest = 'guest',
  Derniere = 'derniere',
};

export enum EAvailability {
  Available = 'available',
  SoldOut = 'sold-out',
};

export interface IPrice {
  value: number;
  currency: string;
};

export interface IRepertoarItem {
  title: string;
  description?: string;
  date: string;
  image: string;
  price?: IPrice;
  category: ECategory;
  kind: EKind;
  availability: EAvailability;
  link: string;
  fbEvent?: string;
};

export interface IRepertoar {
  month: string;
  data: IRepertoarItem[];
}
