import React from 'react';
import { Row, Col } from 'reactstrap';
import format from 'date-fns/format';
import locale from 'date-fns/locale/cs';
import isPast from 'date-fns/is_past';
import isToday from 'date-fns/is_today';

const itemKind = item => {
  if (item.category === 'concert') {
    return 'koncert';
  }
  if (item.kind === 'premiere') {
    return 'premiéra';
  }
  if (item.kind === 'derniere') {
    return 'derniéra';
  }
  if (item.kind === 'guest') {
    return `host: ${item.guest}`;
  }
};
const RepertoarList = ({ data, navigation }) => {
  let itemsCount = 0;
  return data.map((item) => {
    if (isPast(item.date) && !isToday(item.date)) {
      return null;
    }
    itemsCount = itemsCount + 1;
    return (
      <Row key={item.date} className="repertoar-item">
        <Col lg="3" md="24" className="inner-navigation">
          {(itemsCount === 1) ? navigation : null}
        </Col>
        <Col lg="9" md="24">
          {item.fbEvent && (
            <a href={item.fbEvent} target="_blank" rel="noopener noreferrer">
              <img src={item.image} alt={item.title} className="img-fluid" />
            </a>
          )}
          {!item.fbEvent && (
            <img src={item.image} alt={item.title} className="img-fluid" />
          )}
        </Col>
        <Col lg="3" md="24" className="itemDate">
          <p className="item--date">
            {format(item.date, 'dd D. M.', { locale })}
          </p>
          <p className="item--time">
            {format(item.date, 'H.mm', { locale })}
          </p>
          <p className="item--kind">
            {itemKind(item)}
          </p>
        </Col>
        <Col lg="9" md="24" className="itemDetail" style={{ minHeight: '8rem' }}>
          <h2
            className={`itemTitle itemTitle--kind__${item.kind} itemTitle--category__${item.category}`}
          >
            {item.fbEvent && (
              <a href={item.fbEvent} target="_blank" rel="noopener noreferrer">
                {item.title}
              </a>
            )}
            {!item.fbEvent && item.title}
          </h2>
          <p>{item.description}</p>
          {item.price && item.price.value && (
            <p>cena: {item.price.value} {item.price.currency}</p>
          )}
          <div className="item--button">
            {(item.availability === 'available' && item.link) && (
              <a href={item.link} title="vstupenky" target="_blank" rel="noopener noreferrer">vstupenky</a>
            )}
            {item.availability === 'sold-out' && (
              <span>vyprodáno</span>
            )}
          </div>
        </Col>
      </Row>
    );
  });
};

export default RepertoarList;
